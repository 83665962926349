const menuToggle = (btn, el) => {
    btn.addEventListener('click', ()=> {
        let expanded = btn.getAttribute('aria-expanded');
        if ( expanded === 'true') {
            btn.setAttribute('aria-expanded', 'false');
        } else {
            btn.setAttribute('aria-expanded', 'true');
        }
        el.classList.toggle('menu-open');
    });
};
export { menuToggle };