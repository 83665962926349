import { tns } from './../../../../../../node_modules/tiny-slider/src/tiny-slider';

const heroSlider = document.querySelector('.hero-slider');
const mainTeasersSlider = document.querySelector('.main-teasers--slider');
const sponsorSlider = document.querySelector('.main-sponsors--slider');

function initSliders() {
    if (heroSlider) {
        tns({
            container: heroSlider,
            items: 1,
            mode: 'gallery',
            autoplay: true,
            autoplayButtonOutput: false,
            autoHeight: true,
            controls: false,
            nav: false,
            speed: 800
        })
    }
    if (mainTeasersSlider) {
        tns({
            container: mainTeasersSlider,
            items: 1,
            mode: 'gallery',
            autoplay: true,
            autoplayTimeout: 10000,
            autoplayButtonOutput: false,
            autoHeight: false,
            nav: false,
            responsive: {
                640: {
                    items: 2
                },
                940: {
                    items: 3
                },
                1488: {
                    items: 4
                }
            }
        })
    }
    if (sponsorSlider) {
        tns({
            container: sponsorSlider,
            items: 1,
            autoplay: true,
            autoplayButtonOutput: false,
            autoHeight: false,
            nav: false,
            autoplay: false,
            responsive: {
                640: {
                    items: 3
                },
                940: {
                    items: 4
                },
                1488: {
                    items: 6
                }
            }
        })
    }
}

export default () => {
    initSliders();
}
