const calculateSurcharge = () => {
	let items = document.querySelectorAll('.woocommerce-table__line-item.order_item')
	if (items.length > 0) {
		items.forEach(item => {
			let count = 0
			item.querySelectorAll('.wc-item-meta ul li').forEach(attribute => {
				if (attribute.textContent.toLowerCase() == 'mitglied: nein') {
					count++
				}
			})
			if (count > 0) {
				let surcharge = item.querySelector('.surcharge').dataset.surcharge * count
				item.querySelector('.surcharge').remove()
				item.querySelector('.woocommerce-Price-amount.amount span').remove()
				let regularPrice = parseFloat(item.querySelector('.woocommerce-Price-amount.amount').textContent.replace(',', '.'))
				item.querySelector('.woocommerce-Price-amount.amount').textContent = (regularPrice + surcharge).toFixed(2).replace('.', ',') + '€'
			}
		})
	}
}

export { calculateSurcharge }
