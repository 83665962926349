export default () => {
	const prowinLoginBtn = jQuery('#prowinner-login input[type=submit]')
	const prowinLogoutBtn = jQuery('#prowinner-logout input[type=submit], #top-navigation a#logout')
	const gotoLoginPageBtn = jQuery('a[data-url="login-from-member-page"]')
	const showPassword = jQuery('#show-password')

	gotoLoginPageBtn.on('click', (e) => {
		e.preventDefault()
		const this_path = window.location.pathname.substring(1)
		const login_page_url = e.target.href
		window.location.href = login_page_url + '#lasturl=' + this_path
	})

	prowinLoginBtn.on('click', (e) => {
		e.preventDefault()
		prowinLoginBtn.prop('disabled', true)
		const lastVisitedUrl = window.location.origin + '/' + window.location.hash.substring(9)

		let name = jQuery('#prowinner-login input[name=name]').val()
		let pw = jQuery('#prowinner-login input[name=password]').val()
		let errorMessage = jQuery('#prowinner-login .form-error')

		errorMessage.attr('hidden', true)

		jQuery.ajax({
			type: 'POST',
			url: tc_scripts.url,
			data: {
				action: 'ajaxStartSession',
				uname: name,
				upass: pw
			},
			success: function (data, textStatus, XMLHttpRequest) {
				if (data.success === true) {
					window.location.href = lastVisitedUrl
				} else {
					errorMessage.attr('hidden', false)
					prowinLoginBtn.prop('disabled', false)
				}
			},
			error: function (XMLHttpRequest, textStatus, errorThrown) {
				console.log('error')
				errorMessage.attr('hidden', false)
				prowinLoginBtn.prop('disabled', false)
			}
		})
	})

	prowinLogoutBtn.on('click', (e) => {
		e.preventDefault()
		let redirectUrl = prowinLogoutBtn.attr('data-redirect')
		jQuery.ajax({
			type: 'POST',
			url: tc_scripts.url,
			data: {
				action: 'ajaxCloseSession'
			},
			success: function (data, textStatus, XMLHttpRequest) {
				window.location.href = redirectUrl
			}
		})
	})


	showPassword.on('click', (e) => {
		e.preventDefault()
		let type = jQuery('#password-input').attr('type')
		jQuery('#show-password').toggleClass('shown')
		if (type == 'password') {
			jQuery('#password-input').attr('type', 'text')
		} else {
			jQuery('#password-input').attr('type', 'password')
		}
	})
}