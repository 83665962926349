import { vars } from '../base/variables';

const menuDropdown =  (parents, firstItemDropd = false ) => {
    for ( let parentMenulink of parents ) {
        const link = parentMenulink.querySelector('a'); 
        const href = link.getAttribute('href');
        const pclass = parentMenulink.getAttribute('class');

        const arrow = document.createElement("div");
        arrow.classList.add('menu-arrow');
        parentMenulink.appendChild(arrow);
        arrow.addEventListener('click', function() {
            this.parentNode.classList.toggle('show-submenu');
        });

        if ( firstItemDropd ) {
            parentMenulink.firstChild.addEventListener('click', function() {
                this.parentNode.classList.toggle('show-submenu');
            });
            if ( pclass.indexOf('current-menu-item') >= 0 ) {
                parentMenulink.classList.add('show-submenu');
            }
        } 
        else {
            if ( href == '#' || href === null ) {
                parentMenulink.firstChild.addEventListener('click', function() {
                    this.parentNode.classList.toggle('show-submenu');
                });
            }
        }
    };
}

export { menuDropdown };