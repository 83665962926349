import Accordion from "./../libs/accordion/accordion";

function initAccordions() {
    let accordions = document.querySelectorAll(".accordion--wrapper");
    if ( accordions ) {
        for(const el of accordions) {
            new Accordion(el, {
                    modal: true
                }
            );
        }
    }
}

export default () => {
    initAccordions();
}

