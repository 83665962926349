const autoOpenTicketFields = () => {
	let tickets = document.querySelectorAll('.wc-box-office-ticket-fields')
	if (tickets.length > 0) {
		setTimeout(() => {
			tickets = document.querySelectorAll('.wc-box-office-ticket-fields')
			if (tickets.length > 0) {
				openTickets()
			}
		}, 100)
		document.querySelector('.arrow-plus').addEventListener('click', () => {
			tickets = document.querySelectorAll('.wc-box-office-ticket-fields')
			openTickets()
		})
		function openTickets() {
			tickets.forEach(ticket => {
				if (!ticket.classList.contains('active')) {
					ticket.classList.add('active')
				}
			})
		}
	}
}

export { autoOpenTicketFields }
