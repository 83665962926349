import { vars } from '../base/variables';

export default () => {
    // const toTop = document.querySelector('.back-to-top');
    // const greyBar = document.querySelector('#grey-bar-header');
    // const heroBlocks = document.querySelectorAll('.hero-block');
    let y1 = 0;

    window.addEventListener('scroll', function(e){
        let bodyClasses = vars.body[0].getAttribute('class');
        let y = window.scrollY;
        if ( y > 50) {
            vars.body[0].classList.add('small-nav');
        } else {
            vars.body[0].classList.remove('small-nav');
        }
        // Close flyout
        if ( bodyClasses.indexOf('flyout-open') !== -1 ) {
            vars.body[0].classList.remove('flyout-open');
        }
    });
}