import datepicker from "./../../../../../../node_modules/js-datepicker/src/datepicker";
import '../libs/googleapi';

var calendar = 0,
	calendars = [],
	calendarItems = [],
	startDate,
	endDate,
	showAll = true;

const checkSelectedCalenders = () => {
	var selectedCalendars = document.querySelector("#main").classList;
	if (selectedCalendars) {
		if (selectedCalendars.contains("verein-cal")) {
			calendars.push("44de89k9k52bara3lk6ougj8hg@group.calendar.google.com");
		}
		if (selectedCalendars.contains("tennis-cal")) {
			calendars.push("1re69gkvkri5djotvl0i5n2vdk@group.calendar.google.com");
		}
		if (selectedCalendars.contains("hockey-cal")) {
			calendars.push("i3d5559lusncg9jpup9u3q4mas@group.calendar.google.com");
		}
		if (selectedCalendars.contains("gastronomie-cal")) {
			calendars.push("ns634cf71kkmrnu9g98s9km5hk@group.calendar.google.com");
		}
	}
}

if (document.getElementById("cal-container")) {
	checkSelectedCalenders();
	document.getElementById("left-arrow-cal").addEventListener("click", (e) => {
		calendarItems = [];
		(showAll) ? calendar = 0 : null;
		var choice = document
			.getElementsByClassName("select-selected")[0]
			.getAttribute("value");
		switch (choice) {
			case "today":
				startDate.setDate(startDate.getDate() - 1);
				startDate.setHours(0, 0, 0, 0);
				endDate.setDate(endDate.getDate() - 1);
				endDate.setHours(0, 0, 0, 0);
				break;
			case "week":
				startDate.setDate(startDate.getDate() - 7);
				startDate.setHours(0, 0, 0, 0);
				endDate.setDate(endDate.getDate() - 7);
				endDate.setHours(0, 0, 0, 0);
				break;
			case "next-month":
				var date = startDate;
				var year = date.getFullYear();
				var month = date.getMonth();
				startDate = new Date(year, month, 1);
				endDate = new Date(year, month + 1, 0);
				break;
			default:
				var date = startDate;
				var year = date.getFullYear();
				var month = date.getMonth();
				startDate = new Date(year, month - 1, 1);
				endDate = new Date(year, month, 0);
				break;
		}
		gcalendar();
	});
	document.getElementById("right-arrow-cal").addEventListener("click", (e) => {
		calendarItems = [];
		(showAll) ? calendar = 0 : null;
		var choice = document
			.getElementsByClassName("select-selected")[0]
			.getAttribute("value");
		switch (choice) {
			case "today":
				startDate.setDate(startDate.getDate() + 1);
				startDate.setHours(0, 0, 0, 0);
				endDate.setDate(endDate.getDate() + 1);
				endDate.setHours(0, 0, 0, 0);
				break;
			case "week":
				startDate.setDate(startDate.getDate() + 7);
				startDate.setHours(0, 0, 0, 0);
				endDate.setDate(endDate.getDate() + 7);
				endDate.setHours(0, 0, 0, 0);
				break;
			default:
				var date = startDate;
				var year = date.getFullYear();
				var month = date.getMonth();
				startDate = new Date(year, month + 1, 1);
				endDate = new Date(year, month + 2, 0);
				break;
		}
		gcalendar();
	});
}
const gcalendar = () => {
	if (document.getElementsByClassName("calendars")[0]) {
		var filters = document
			.getElementsByClassName("calendars")[0]
			.getElementsByTagName("p");

		for (let i = 0; i < filters.length; i++) {
			const el = filters[i];
			el.addEventListener("click", (e) => {
				e.preventDefault();
				calendar = i - 1;
				if (i != 0) {
					calendarItems = [];
					showAll = false;

				} else {
					showAll = true;
				}
				for (let i = 0; i < filters.length; i++) {
					filters[i].classList.remove("active");
				}
				el.classList.add("active");
				printCalendar();
			});
		}
	}
	var timeSelect = document
		.getElementsByClassName("select-items")[0]
		.getElementsByTagName("div");

	for (let i = 0; i < timeSelect.length; i++) {
		const el = timeSelect[i];
		el.addEventListener("click", (e) => {
			e.preventDefault();
			var date = new Date();
			var year = date.getFullYear();
			var month = date.getMonth();
			switch (el.getAttribute("value")) {
				case "today":
					startDate = new Date();
					endDate = new Date();
					endDate.setDate(endDate.getDate() + 1);
					endDate.setHours(0, 0, 0, 0);
					break;
				case "week":
					var curr = new Date();
					var first = curr.getDate() - curr.getDay() + 1;
					startDate = new Date(curr.setDate(first));
					startDate.setHours(0, 0, 0, 0);
					endDate = new Date(curr.setDate(first + 6));
					endDate.setHours(23, 59, 59, 999);
					break;
				case "next-month":
					startDate = new Date(year, month + 1, 1);
					endDate = new Date(year, month + 2, 0);
					break;
				case "range":
					//Range picker
					break;
				default:
					startDate = new Date(year, month, 1);
					endDate = new Date(year, month + 1, 0);
					break;
			}
			printCalendar();
		});
	}

	function printCalendar() {
		switchTimeRanges();
		if (showAll) {
			for (let i = 0; i < calendars.length; i++) {
				renderCalendar(i);
			}
		} else {
			renderCalendar(calendar);
		}
		// console.log('Runned calendar :' + calendar);
	}

	function switchTimeRanges() {
		var date = new Date();
		var year = date.getFullYear();
		var month = date.getMonth();
		if (startDate === undefined) {
			startDate = new Date(year, month, 1);
		}
		if (endDate === undefined) {
			endDate = new Date(year, month + 1, 0);
		}

		switch (
		document
			.getElementsByClassName("select-selected")[0]
			.getAttribute("value")
		) {
			case "today":
				document.getElementById(
					"date-head"
				).innerHTML = startDate.toLocaleString("de-DE", {
					weekday: "long",
					year: "numeric",
					month: "long",
					day: "numeric",
				});
				break;
			case "week":
				var startMonth = startDate.toLocaleString("de-DE", {
					month: "long",
				});
				var endMonth = endDate.toLocaleString("de-DE", {
					month: "long",
				});
				if (startMonth == endMonth) {
					startMonth = "";
				}
				document.getElementById("date-head").innerHTML =
					"Woche vom " +
					(startDate.getUTCDate() + 1) +
					". " +
					startMonth +
					" bis " +
					(endDate.getUTCDate() + 1) +
					". " +
					endMonth +
					" " +
					endDate.getFullYear();
				break;
			case "range":
				document.getElementById("date-head").innerHTML =
					startDate.toLocaleString("de-DE", {
						weekday: "long",
						year: "numeric",
						month: "long",
						day: "numeric",
					}) +
					" - " +
					endDate.toLocaleString("de-DE", {
						weekday: "long",
						year: "numeric",
						month: "long",
						day: "numeric",
					});
				break;
			default:
				document.getElementById(
					"date-head"
				).innerHTML = startDate.toLocaleString("de-DE", {
					year: "numeric",
					month: "long",
				});
				break;
		}
	}

	function renderCalendar(key) {
		gapi.client
			.init({
				apiKey: "AIzaSyCeFJCU5R3E4NZhBsWbqiKEpFYILuNbic8",
				discoveryDocs: [
					"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
				],
			})
			.then(function () {
				return gapi.client.calendar.events.list({
					calendarId: calendars[key],
					timeZone: "Europe/Berlin",
					singleEvents: true,
					timeMin: startDate.toISOString(),
					timeMax: endDate.toISOString(),
					orderBy: "startTime",
					eventTypes: ["default", "focusTime", "outOfOffice"],
				});
			})
			.then(
				function (response) {
					response.result.items.forEach(function (entry) {
						calendarItems.push(entry);
					});

					if (calendarItems) {
						calendarItems.sort(compare);
						calendarItems = calendarItems.filter(
							(v, i, a) => a.findIndex((t) => t.id === v.id) === i
						);
						var getNowPlayingDiv = document.getElementById("cal-container");
						var calendarDiv = [""];
						var auxDate = "";
						var first = true;
						calendarItems.forEach(function (entry) {
							var newDay = false;
							var entryStart, entryEnd;
							if (entry.start.dateTime == undefined) {
								entryStart = entry.start.date;
							} else {
								entryStart = entry.start.dateTime;
							}
							if (entry.end.dateTime == undefined) {
								entryEnd = entry.end.date;
							} else {
								entryEnd = entry.end.dateTime;
							}
							if (entryStart.substring(0, 10) != auxDate) {
								newDay = true;
							}
							auxDate = entryStart.substring(0, 10);
							var formattedTime;
							if (entryStart.length < 11) {
								formattedTime = "";
							} else if (entryStart) {
								var startTime =
									entryStart === undefined
										? ""
										: entryStart.substring(11, 16);
								var endTime =
									entryEnd === undefined
										? ""
										: " - " + entryEnd.substring(11, 16) + " Uhr";
								formattedTime = startTime + endTime;
							}
							var location =
								entry.location === undefined
									? ""
									: "<p class='location'>" + entry.location + "</p>";
							var summary =
								entry.summary === undefined
									? ""
									: "<p class='summary'>" + entry.summary + "</p>";
							var description =
								entry.description === undefined
									? ""
									: "<p class='description'>" + entry.description + "</p>";
							var start = new Date(Date.parse(entryStart));
							var startDay = "",
								endDay = "";
							if (start != null && newDay) {
								startDay = '<div class="start-day">';
								if (!first) {
									endDay = "</div>";
								}
							}
							calendarDiv.push(
								"" +
								endDay +
								startDay +
								'<div class="event-day">' +
								'<div class="day">' +
								'<p class="number">' +
								start.getDate() +
								"</p>" +
								'<p class="weekday">' +
								start.toLocaleString("de-DE", { weekday: "short" }) +
								"</p>" +
								"</div>" +
								'<div class="event">' +
								"<div>" +
								summary +
								"<p class='time'>" +
								formattedTime +
								"</p>" +
								location +
								"</div>" +
								description +
								"</div>" +
								"</div>"
							);
							first = false;
						});
						getNowPlayingDiv.innerHTML = calendarDiv.join("");
						(!showAll) ? calendarItems = [] : null;
					}
					// }
				},
				function (reason) {
					console.log("Error: " + reason.result.error.message);
				}
			);
	}
	gapi.load("client", printCalendar);
};

const initCalPicker = () => {
	const date_input = document.querySelector('div[value="range"]');
	date_input.addEventListener("click", (e) => {
		e.preventDefault();
		document.getElementById("end-calendar").style.display = "block";
		document.getElementById("start-calendar").style.display = "block";
	});
	var date = new Date();
	const start = datepicker("#start-cal", {
		id: 1,
		onSelect: (instance) => {
			startDate = instance.dateSelected;
			if (
				start.getRange().start != undefined &&
				start.getRange().end != undefined
			) {
				gcalendar();
				document.getElementById("end-calendar").style.display = "none";
				document.getElementById("start-calendar").style.display = "none";
			}
		},
		customDays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
		customMonths: [
			"Januar",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember",
		],
		startDate: date,
		showAllDates: true,
	});
	start.calendarContainer.id = "start-calendar";
	const end = datepicker("#end-cal", {
		id: 1,
		onSelect: (instance) => {
			endDate = instance.dateSelected;
			endDate.setHours(23, 59, 59, 999);
			if (
				start.getRange().start != undefined &&
				start.getRange().end != undefined
			) {
				gcalendar();
				document.getElementById("end-calendar").style.display = "none";
				document.getElementById("start-calendar").style.display = "none";
			}
		},
		customDays: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
		customMonths: [
			"Januar",
			"Februar",
			"März",
			"April",
			"Mai",
			"Juni",
			"Juli",
			"August",
			"September",
			"Oktober",
			"November",
			"Dezember",
		],
		startDate: new Date(date.setMonth(date.getMonth() + 1)),
		showAllDates: true,
	});
	end.calendarContainer.id = "end-calendar";
};

export { gcalendar, initCalPicker };

// Function to sort the calendar events by start date
function compare(a, b) {
	var aDate = a.start.date;
	var bDate = b.start.date;
	if (aDate == undefined) {
		aDate = a.start.dateTime;
	}
	if (bDate == undefined) {
		bDate = b.start.dateTime;
	}
	if (aDate < bDate) {
		return -1;
	}
	if (aDate > bDate) {
		return 1;
	}
	return 0;
}
