import { vars } from '../base/variables';

const initFlyout = (parents, targets, body) => {
	body.classList.remove('menu-open'); // from mobile - js

	let isActive = false;
	let menuID = null;
	let navs = document.querySelectorAll('#top-navigation, #flyout-navigation');

	const hideFlyout = (isTarget) => {
		for (let subitem of targets) {
			subitem.classList.remove('show');
		};
		if (!isTarget) {
			body.classList.remove('flyout-open');
		}
	}

	for (let item of parents) {
		const lid = item.id;
		const link = item.querySelector('a');
		link.addEventListener('mouseenter', function () {
			for (let subitem of targets) {
				subitem.classList.remove('show');
				let subitemClass = subitem.getAttribute('class');
				if (subitemClass.indexOf(lid) !== -1) {
					setTimeout(() => { subitem.classList.add('show') }, 10); // IE11 error
				}
				body.classList.add('flyout-open');
				isActive = true;
			};
			menuID = lid;
			body.classList.add('flyout-open');
		});
	};
	for (let div of navs) {
		div.addEventListener('mouseleave', function () {
			hideFlyout(false);
		});
	}
	// Same height as hero header
	const heroHeader = document.querySelector('header.hero-header')
	if (heroHeader) {
		window.addEventListener('load', () => {
			document.querySelector('#flyout-navigation').style.minHeight = heroHeader.clientHeight + 'px'
		})
		window.addEventListener('resize', () => {
			document.querySelector('#flyout-navigation').style.minHeight = heroHeader.clientHeight + 'px'
		})
	}
}

export { initFlyout };