import { seatsBooking } from "./seats-booking";

const initShop = () => {
	// const button = document.querySelector('#checkout-booked-timeslots');
	// if (button) {
	// 	button.addEventListener('click', (e) => {
	// 		//TODO: Get selected products. If you want to empty the cart, you can leave productList empty.
	// 		var productList = [
	// 			{ product_id: 383, quantity: 1, variation_id: 384, day: '11.11.11', time: '11:11', players: ['Niklas', 'Matheus', 'Krzysztof'], court: 'Olympiastadion' },
	// 			{ product_id: 383, quantity: 2, variation_id: 385, day: '22.22.22', time: '22:22', players: ['Marco', 'Jadon', 'Erling'], court: 'Signal Iduna Park' },
	// 			{ product_id: 383, quantity: 3, variation_id: 386, day: '33.33.33', time: '33:33', players: ['Robert', 'Joshua', 'Alphonso'], court: 'Allianz Arena' },
	// 		];
	// 		e.preventDefault();
	// 		jQuery.ajax({
	// 			type: 'POST',
	// 			url: tc_scripts.url,
	// 			data: {
	// 				action: 'toCheckout',
	// 				products: productList
	// 			},
	// 			success: function (data, textStatus, XMLHttpRequest) {
	// 				window.location = button.getAttribute('checkout-url');
	// 			},
	// 		});
	// 	});
	// }

	// basic js show/hide pagination
	const shop_categories = document.querySelectorAll('.shop-category-items');

	shop_categories.forEach((cat) => {
		const items = cat.querySelectorAll('.product');
		const btn = cat.querySelector('.load-more');
		const limit = btn.getAttribute('data-limit');
		const length = items.length;
		const pages = items.length / limit;
		// Hide paginated items
		for (let i = 0; i < length; i++) {
			if (i >= parseFloat(limit)) {
				items[i].style.display = 'none';
			}
		}
		if (pages >= 1) {
			btn.addEventListener('click', (e) => {
				e.preventDefault;
				let page = e.target.getAttribute('data-page');
				if (page < pages) {
					const next = parseFloat(page) + 1;
					const start = parseFloat(page) * parseFloat(limit);
					const end = next * parseFloat(limit);
					// console.log(next, start, end);
					for (let i = start; i < end; i++) {
						(items[i]) ? items[i].style.display = 'block' : null;
					}
					e.target.setAttribute('data-page', next);
					if (next > pages) {
						btn.style.display = 'none';
					}
				}
			});
		} else {
			btn.style.display = 'none';
		}
	})
}
const initProductGallery = () => {
	const imgLinks = document.querySelectorAll('.single-product .woocommerce-product-gallery__wrapper > .woocommerce-product-gallery__image > a');
	const length = imgLinks.length;
	const frame = document.getElementById('custom-woocommerce-gallery-frame');

	if (frame && imgLinks) {
		const parent = frame.parentNode;
		(frame) ? parent.setAttribute('data-count', length) : null;
		imgLinks.forEach((a, index) => {
			const imgSrc = a.getAttribute('href');
			const img = document.createElement('img');
			img.setAttribute('src', imgSrc);
			img.classList.add('invisible');
			frame.appendChild(img);
			const frameImgs = frame.childNodes;
			frameImgs[0].classList.remove('invisible');
			frameImgs[0].classList.add('visible');
			a.addEventListener('click', (e) => {
				e.preventDefault();
				showHideImgs(frameImgs, index);
			});
		});
	}

	const showHideImgs = (imgs, index) => {
		imgs.forEach((img) => {
			img.classList.remove('visible');
			img.classList.add('invisible');
		});
		imgs[index].classList.remove('invisible');
		imgs[index].classList.add('visible');
	}
}

const initQty = () => {
	window.qty_update = function (el, x) {
		const parent = el.parentNode;
		const input = parent.querySelector('input.qty');
		if (input) {
			const min = parseFloat(input.getAttribute('min')) ? parseFloat(input.getAttribute('min')) : 0;
			const max = parseFloat(input.getAttribute('max')) ? parseFloat(input.getAttribute('max')) : 9999;
			const val = parseInt(input.value);
			if ((x == -1 && val > min) || (x == 1 && val < max)) {
				input.value = val + x;
				update();
			}
		}
		function update() {
			if (document.getElementsByName('update_cart').length > 0) {
				document.getElementsByName('update_cart').forEach(el => {
					el.disabled = false;
					el.classList.add('update_cart');
				})
			} else {
				var ev = new Event('change', { bubbles: true });
				input.dispatchEvent(ev);
			}
		}
	}
}

const initHistoryBack = () => {
	const h = document.querySelector('.history-back');
	if (h) {
		document.querySelector('.history-back').addEventListener('click', (e) => {
			e.preventDefault();
			history.back();
		});
	}
}

const initShippingOverlay = () => {
	const link = document.querySelector('.single-product .shipping-costs-info > a');
	const overlay = document.querySelector('.single-product .shipping-costs-overlay');
	if (link && overlay) {
		link.addEventListener('click', (e) => {
			e.preventDefault();
			overlay.classList.toggle('show');
		});
		const close = document.querySelector('.single-product .shipping-costs-overlay .close-button');
		if (close) {
			close.addEventListener('click', (e) => {
				e.preventDefault();
				overlay.classList.remove('show');
			});
		}
	}
}

const initCollectionAttributesRadio = () => {
	const attribRadios = document.querySelectorAll('.single-product .attribute-select-radio-group-wrap input[type="radio"]');
	const variationsForm = document.querySelector('form.variations_form');
	if (attribRadios.length > 0)
	{
		attribRadios.forEach((radioItem) => {
			radioItem.addEventListener('change', (e) => {
				var corresponding_select = document.querySelector('.single-product select[name="' + e.target.dataset.attribute_name + '"]');
				//console.log('clack', radioItem, e.target.dataset.attribute_name, e.target.value, corresponding_select);

				corresponding_select.value = e.target.value;
				//corresponding_option.dispatchEvent(new Event('click'));
				variationsForm.dispatchEvent(new Event('check_variations'))
			});
		});
	}
	
}

const initShopJS = () => {
	initShop();
	initProductGallery();
	initQty();
	initHistoryBack();
	initShippingOverlay();
	initCollectionAttributesRadio();
	seatsBooking.init();
}

export { initShopJS };