const initYoutubePlayers = (players) => {

	var playerHeight = 576;
	var playerWidth = 1024;

	// var tabs = document.querySelectorAll('.service--video-tabs .tab');
	if (players) {
		players.forEach((ytvideo, i) => {
			var video_id = ytvideo.getAttribute('data-id');
			var playbtn = ytvideo.nextElementSibling.querySelector('.video-play-btn');
			var overlay = ytvideo.nextElementSibling;
			var thisplayer = 'youtube-player-' + i;
			var player;

			// YT.Player from https://www.youtube.com/iframe_api
			player = new YT.Player(ytvideo, {
				height: '100%',
				width: '100%',
				videoId: video_id,
				playerVars: { rel: 0, showinfo: 0, ecver: 2 },
				events: {
					// 'onReady': onPlayerReady,
					// 'onStateChange': onPlayerStateChange
				}
			});

			playbtn.addEventListener('click', function () {
				// overlay.classList.add('hide-start');
				setTimeout(function () {
					playVideo();
				}, 200);
				setTimeout(function () {
					overlay.classList.add('hide-done');
				}, 700);
			});

			function playVideo() {
				player.playVideo();
			}
			function pauseVideo() {
				player.pauseVideo();
			}
		});
	}
}
const initHtmlPlayers = (players) => {
	if (players) {
		players.forEach((video, i) => {
			var playbtn = video.nextElementSibling.querySelector('.video-play-btn');
			var overlay = video.nextElementSibling;
			playbtn.addEventListener('click', function () {
				setTimeout(function () {
					overlay.classList.add('hide-done');
				}, 100);
				setTimeout(function () {
					video.play();
				}, 200);
			});
		});
	}
}
export { initYoutubePlayers, initHtmlPlayers };
