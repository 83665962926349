const zoomModal = () => {
	const modalImages = document.querySelectorAll('#custom-woocommerce-gallery-frame img')
	if (modalImages.length > 0) {
		modalImages.forEach(img => {
			var modal = document.getElementById('image-zoom')
			var modalImg = document.querySelector('#image-zoom__content')
			img.addEventListener('click', () => {
				modal.style.display = 'block'
				modalImg.src = img.src
			})

			var span = document.querySelector('#image-zoom__close')
			span.onclick = function () {
				modal.style.display = 'none'
			}
		})
	}
}

export { zoomModal }