import SimpleLightbox from "./../../../../../../node_modules/simplelightbox/dist/simple-lightbox.modules.js";

const initLightbox = () => {
  let lightboxes = document.querySelectorAll(".gallery-block .image a, .webcams a");
  if ( lightboxes.length > 0 ) {
    const lightbox = new SimpleLightbox(lightboxes, {
      widthRatio: 1,
      heightRatio: 1,
      maxZoom: 50,
      captions: false,
      showCounter: false
    }); 
  }
};

export default () => {
  initLightbox();
};
