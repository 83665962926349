const initBarcodeTable = () => {
	// Remove first option from select.
	if (document.querySelector('#scan-action')) {
		document.querySelector('#scan-action option:first-child').remove();
	}
	if (document.getElementById('barcode-table')) {
		const ticketData = document.querySelectorAll('.ticket-data');
		ticketData.forEach((ticket) => {
			let firstLine = ticket.querySelector('li');
			firstLine.querySelector('strong').remove();
			firstLine.innerHTML = firstLine.innerHTML.replace(': ', '');
		});
		document.getElementById('barcode-search').addEventListener('keyup', function () {
			var input, filter, table, tr, td, i, txtValue;
			input = document.getElementById('barcode-search');
			filter = input.value.toUpperCase();
			table = document.getElementById('barcode-table');
			tr = table.getElementsByTagName('tr');

			// Loop through all table rows, and hide those who don't match the search query
			for (i = 0; i < tr.length; i++) {
				td = tr[i].getElementsByTagName('td')[0];
				if (td) {
					txtValue = td.textContent || td.innerText;
					if (txtValue.toUpperCase().indexOf(filter) > -1) {
						tr[i].style.display = '';
					} else {
						18 * 24
						tr[i].style.display = 'none';
					}
				}
			}
		});
		// Clear field on submission
		const form = document.querySelector('#ticket-scan-form form');
		if (form) {
			setTimeout(() => {
				onScan.detachFrom(document)
			}, 200);
			form.addEventListener('submit', function (e) {
				setTimeout(() => {
					document.querySelector('#scan-code').value = '';
				}, 1000);
			});
		}
		document.querySelectorAll('.ticket-check').forEach((ticket) => {
			ticket.addEventListener('click', function (e) {
				e.preventDefault();
				let ticketCode = ticket.dataset.ticket;
				document.querySelector('#scan-code').value = ticketCode;
				document.querySelector('h1').scrollIntoView();
				const form = document.querySelector('#ticket-scan-form form');
				const loader = document.getElementById('ticket-scan-loader');
				const input = document.querySelector('#ticket-scan-form input#scan-code');
				const displayResult = document.getElementById('ticket-scan-result');
				loader.style.display = 'block';
				displayResult.innerHTML = '';
				const inputAction = document.querySelector('#ticket-scan-form #scan-action').value;
				const request = new XMLHttpRequest();
				request.open('POST', wc_box_office.ajaxurl, true);
				request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
				request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
				request.onreadystatechange = function () {
					if (this.readyState === XMLHttpRequest.DONE && 200 === this.status) {
						if (input instanceof Element) {
							// Focus on barcode input field.
							input.focus();
						}
						if (!request.response) {
							return;
						}
						// Hide the loader.
						loader.style.display = 'none';
						// Display response.
						displayResult.innerHTML = request.response;
					}
					return;
				}
				request.send(encodeURI('action=scan_ticket&barcode_input=' + input.value + '&scan_action=' + inputAction + '&woocommerce_box_office_scan_nonce=' + wc_box_office.scan_nonce));
				document.querySelector('#scan-code').value = '';
			});
		});
	}
}


export default () => {
	initBarcodeTable();
}