////////////////////////////////////////////////
// Import
////////////////////////////////////////////////
if (
	typeof NodeList !== 'undefined' &&
	NodeList.prototype &&
	!NodeList.prototype.forEach
) {
	// Yes, there's really no need for `Object.defineProperty` here
	NodeList.prototype.forEach = Array.prototype.forEach
}

import initNavigation from './components/navigation'
import initClubityLogin from './components/clubity-login'
import initScroll from './components/scroll'
import initSliders from './components/sliders'
import initAccordions from './components/accordions'
import initLightbox from './components/simplelightbox'
import smooth from './components/smooth'
import { gcalendar, initCalPicker } from './components/googlecal'
import { initYoutubePlayers, initHtmlPlayers } from './components/video-player'
import { initShopJS } from './components/shop'
import { initNameCloud } from './components/namecloud'
import { customDateField } from './components/custom-date'
import { autoOpenTicketFields } from './components/open-fields'
import { ticketLink } from './components/ticket-link'
import { calculateSurcharge } from './components/calculate-surcharge'
import { cartButton } from './components/cart-button'
import { zoomModal } from './components/zoom-modal'
import initBarcodeTable from './components/barcode-table'

////////////////////////////////////////////////
// Events
////////////////////////////////////////////////
document.addEventListener('DOMContentLoaded', () => {
	initClubityLogin()
	initNavigation()
	initScroll()
	initSliders()
	initAccordions()
	initLightbox()
	smooth()
	initShopJS()
	initNameCloud()
	customDateField()
	autoOpenTicketFields()
	ticketLink()
	calculateSurcharge()
	cartButton()
	zoomModal()
	initBarcodeTable()
	document.querySelectorAll('.youtube-player')
		? (window.onYouTubeIframeAPIReady = () => {
			initYoutubePlayers(document.querySelectorAll('.youtube-player'))
		})
		: null
	if (document.querySelector('video')) {
		initHtmlPlayers(document.querySelectorAll('video'))
	}
	if (document.getElementsByClassName('calendar-page').length > 0) {
		gcalendar()
	}
	if (document.querySelector('.seats-plan')) {
		document.querySelector('body').classList.add('seats-template')
	}
})