const cartButton = () => {
	const buyButtons = document.querySelectorAll('a.ajax_add_to_cart')
	if (buyButtons.length > 0) {
		buyButtons.forEach(addBtn => {
			addBtn.addEventListener('click', () => {
				document.querySelector('body').setAttribute('ordered-product-id', addBtn.dataset.product_id)
			})
		})
		jQuery(document).ajaxSuccess(function (event, xhr, settings, data) {
			if (settings.url.indexOf('?wc-ajax=add_to_cart') !== -1) {
				let orderedId = document.querySelector('body').getAttribute('ordered-product-id')
				let addedToCartBtn = document.querySelector('li.post-' + orderedId + ' a.added_to_cart.wc-forward')
				let productName = document.querySelector('li.post-' + orderedId + ' h2').textContent
				addedToCartBtn.textContent = productName + ' wurde Deinem Warenkorb hinzugefügt'
				addedToCartBtn.classList.add('woocommerce-message', 'shop-listing-message')
				addedToCartBtn.setAttribute('role', 'alert')
				let toCartBtn = document.createElement('a')
				toCartBtn.classList.add('button', 'add_to_cart_button', 'to-cart-custom')
				toCartBtn.textContent = 'Jetzt bezahlen'
				toCartBtn.href = addedToCartBtn.href
				addedToCartBtn.parentNode.append(toCartBtn)
			}
			if (settings.data != 'action=ajaxGetBasketCount') {
				jQuery.ajax({
					type: 'POST',
					url: tc_scripts.url,
					data: {
						action: 'ajaxGetBasketCount'
					},
					success: function success(response) {
						document.querySelector('#basket-count-indicator').textContent = ' (' + response.articleCount + ')'
					}
				})
			}
		})
	}
	const secondaryBuyButtons = document.querySelectorAll('.secondary-buy-btn')
	if (secondaryBuyButtons.length > 0) {
		secondaryBuyButtons.forEach(secondaryBtn => {
			secondaryBtn.addEventListener('click', (e) => {
				e.preventDefault()
				if (!secondaryBtn.classList.contains('disabled')) {
					document.querySelector('body').setAttribute('ordered-product-id', secondaryBtn.dataset.pid)
					// Set waiting cursor
					secondaryBtn.style.cursor = 'wait';
					let variation = document.querySelector('.variation_id')?.value ?? secondaryBtn.dataset.pid;
					let data = {
						product_id: secondaryBtn.dataset.pid,
						quantity: parseInt(document.querySelector('input[name=quantity]').value ?? '1'),
					};
					
					document.querySelectorAll('input.wc-pao-addon-field').forEach(function(addon_input, idx){
						data[addon_input.id] = addon_input.value;
					});

					jQuery.ajax({
						type: 'POST',
						url: secondaryBtn.dataset.url + '?add-to-cart=' + variation,
						data: data,
						complete: function success(response) {
							// Reset cursor
							secondaryBtn.style.cursor = 'default';
						},
						success: function success(response) {
							// Redirect to cart
							window.location.href = secondaryBtn.dataset.cart
						}
					})
				}
			})
		})
	}
}

export { cartButton }