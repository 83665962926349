const customDateField = () => {
	const dateField = document.querySelectorAll('.woocommerce-checkout .birthday-field')
	if (dateField.length > 0) {
		dateField.forEach(dateFieldEl => {
			let dateFieldInput = dateFieldEl.querySelector('input[type=date]')
			let fromDate = ''
			let toDate = ''
			dateFieldEl.classList.forEach(classEl => {
				if (classEl.indexOf('date-from') != -1) fromDate = classEl.substring(10).split('.')
				if (classEl.indexOf('date-to') != -1) toDate = classEl.substring(8).split('.')
			})
			dateFieldInput.setAttribute('min', fromDate[2] + '-' + fromDate[1] + '-' + fromDate[0])
			dateFieldInput.value = fromDate[2] + '-' + fromDate[1] + '-' + fromDate[0];
			dateFieldInput.setAttribute('max', toDate[2] + '-' + toDate[1] + '-' + toDate[0])
			dateFieldInput.addEventListener('focusout', function (e) {
				if ((Date.parse(dateFieldInput.max) < Date.parse(dateFieldInput.value)) || (Date.parse(dateFieldInput.min) > Date.parse(dateFieldInput.value))) {
					dateFieldInput.value = fromDate[2] + '-' + fromDate[1] + '-' + fromDate[0];
					dateFieldInput.classList.add('error')
				} else {
					dateFieldInput.classList.remove('error')
				}
			})
		});
	}
}

export { customDateField }