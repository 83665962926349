import { vars } from '../base/variables';
import { menuToggle } from '../functions/menu-toggle';
import { menuDropdown } from '../functions/menu-dropdown';
import { initFlyout } from '../functions/menu-flyout';
import { customSelect } from '../functions/custom-select';

export default () => {
    // const flyoutNav = document.getElementById('#flyout-navigation');
    const toggleBtn = document.querySelector('.menu-toggle');
    const mainMenuItems = document.querySelectorAll('#main-navigation li');
    const flyoutNav = document.querySelector('#flyout-navigation');
    const flyoutItems = flyoutNav.querySelectorAll('div > ul > li.menu-item');
    const flyoutMainParentItems = flyoutNav.querySelectorAll('div > ul > li.menu-item-has-children');
    const flyoutSubParentItems = flyoutNav.querySelectorAll('div > ul > li > ul > li.menu-item-has-children');

    customSelect();
    menuToggle(toggleBtn, vars.body[0]);
    initFlyout(mainMenuItems, flyoutItems, vars.body[0]);
    menuDropdown(flyoutMainParentItems, true);
    menuDropdown(flyoutSubParentItems, false);
}