import TagCloud from 'TagCloud';
import $ from 'jquery';

const initNameCloud = () => {
	let container = '.cloud-container';
	let containerNode = document.querySelector(container);
	let block = document.querySelector('.namecloud-block');
	let modalContainer = document.querySelector('.namecloud-block--modals');
	let names = document.querySelector('.namecloud-block--names');

	if (containerNode) {
		let timeout = containerNode?.getAttribute('data-load-timeout');
		let donorList = containerNode.querySelectorAll('ul li span');
		let texts = [];
		const linkItems = () => {
			let tagcloudItems =
				containerNode.querySelectorAll('.tagcloud--item');
			//fill tag cloud with own markup
			for (let i = 0; i < tagcloudItems.length; i++) {
				tagcloudItems[i].appendChild(donorList[i].cloneNode(true));
			}
		};
		const rebuildCloud = () => {
			let options = {
				radius: Math.floor(containerNode.offsetWidth / 2)
			};
			tagCloud.destroy();
			tagCloud = TagCloud(container, texts, options);
			linkItems();
		};
		//initialize empty tag cloud
		donorList?.forEach((item) => {
			texts.push('');
		});
		let options = {
			radius: Math.floor(containerNode.offsetWidth / 2)
		};
		let tagCloud = TagCloud(container, texts, options);

		linkItems();

		let ticking = false;
		window.addEventListener('resize', (e) => {
			if (!ticking) {
				window.requestAnimationFrame(() => {
					rebuildCloud();
					ticking = false;
				});
				ticking = true;
			}
		});

		let autopilot = setInterval(loadNewDonors, timeout);

		containerNode.addEventListener('mousemove', resetInterval);
		containerNode.addEventListener('touchstart', resetInterval);

		block.addEventListener('click', (e) => {
			if (e.target.hasAttribute('data-donor-id')) {
				document
					.querySelector('.namecloud-block--modal.show')
					?.classList.remove('show');
				let donorId = e.target.dataset.donorId;
				let modal = document.querySelector(
					'.namecloud-block--modal[data-donor-id="' + donorId + '"]'
				);
				modal.classList.add('show');
				clearInterval(autopilot);
				containerNode.removeEventListener('mousemove', resetInterval);
				containerNode.removeEventListener('touchstart', resetInterval);
			}
			if (e.target.classList.contains('namecloud-block--modal__close')) {
				let modal = e.target.closest('.namecloud-block--modal');
				modal.classList.remove('show');
				autopilot = setInterval(loadNewDonors, timeout);
				containerNode.addEventListener('mousemove', resetInterval);
				containerNode.addEventListener('touchstart', resetInterval);
			}
		});

		function loadNewDonors() {
			$.ajax({
				type: 'POST',
				url: tc_scripts.url,
				data: {
					action: 'ajaxLoadDonors',
					donors: $('.namecloud-block--cloud').data('name-num'),
				},
				beforeSend: function () {
					$('.namecloud-block--cloud').addClass('loading-new-cloud');
				},
				success: function (response) {
					modalContainer.innerHTML = response.modals;
					names.innerHTML = response.names;
					donorList = containerNode.querySelectorAll('ul li span');
					linkItems();
					rebuildCloud();
					$('.namecloud-block--cloud').removeClass(
						'loading-new-cloud'
					);
				},
			});
		}
		function resetInterval() {
			clearInterval(autopilot);
			autopilot = setInterval(loadNewDonors, timeout);
		}
	}
};

export { initNameCloud };
