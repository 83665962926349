import smoothscroll from "smoothscroll-polyfill";

const smooth = () => {
  smoothscroll.polyfill();
  var scrollToElements = document.getElementsByClassName("scroll-to");
  var scrollContainer = document.getElementById("scroll-container");
  for (const element of scrollToElements) {
    var dot = document.createElement("span");
    dot.addEventListener("click", (e) => {
      e.preventDefault();
      window.scroll({
        top: element.offsetTop - 70,
        left: 0,
        behavior: "smooth",
      });
      for (const el of scrollContainer.childNodes) {
        el.classList.remove("active");
      }
      e.target.classList.add("active");
    });
    scrollContainer.appendChild(dot);
  }

  // scroll categories on shop page
  // const links = document.querySelectorAll('.shop-category-nav a');
  // links.forEach((li)=>{
	// li.addEventListener('click', (e)=>{
	// 	e.preventDefault();
	// 	const target = document.querySelector(e.target.getAttribute('href'));
	// 	window.scroll({
	// 		top: target.offsetTop - 70,
	// 		left: 0,
	// 		behavior: "smooth",
	// 	});
	//   });
  // })
};

export default () => {
  smooth();
};
