const customSelect = () => {
  // FROM https://www.w3schools.com/howto/howto_custom_select.asp
  var x, i, j, selElmnt, a, b, c;
  x = document.querySelectorAll(".custom-select");
  if (x) {
    for (i = 0; i < x.length; i++) {
      selElmnt = x[i].getElementsByTagName("select")[0];
      a = document.createElement("div");
      a.setAttribute("class", "select-selected");
      a.innerHTML = selElmnt.options[0].innerHTML;
      a.setAttribute("value", selElmnt.options[0].value);
      x[i].appendChild(a);
      b = document.createElement("div");
      b.setAttribute("class", "select-items select-hide");
      b.style.backgroundColor = selElmnt.style.backgroundColor;
      b.style.color = selElmnt.style.color;
      b.style.borderColor = selElmnt.style.color;
      for (j = 0; j < selElmnt.length; j++) {
        if (selElmnt.options[j].value == "all") {
          continue;
        }
        c = document.createElement("div");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.setAttribute("value", selElmnt.options[j].value);
        if (j == 0) {
          c.setAttribute("class", "same-as-selected");
        }
        c.addEventListener("click", function (e) {
          var y, i, k, s, h;
          s = this.parentNode.parentNode.getElementsByTagName("select")[0];
          h = this.parentNode.previousSibling;
          for (i = 0; i < s.length; i++) {
            if (s.options[i].innerHTML == this.innerHTML) {
              s.selectedIndex = i;
              h.innerHTML = this.innerHTML;
              h.setAttribute("value", this.getAttribute("value"));
              y = this.parentNode.getElementsByClassName("same-as-selected");
              for (k = 0; k < y.length; k++) {
                y[k].removeAttribute("class");
              }
              this.setAttribute("class", "same-as-selected");
              break;
            }
          }
          h.click();
        });
        b.appendChild(c);
      }
      x[i].appendChild(b);
      a.addEventListener("click", function (e) {
        e.stopPropagation();
          closeAllSelect(this);
          this.nextSibling.classList.toggle("select-hide");
          this.classList.toggle("select-arrow-active");
      });
    }
  }

  function closeAllSelect(elmnt) {
    var x,
      y,
      i,
      arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i);
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }

  jQuery(window).on("click", function () {
    closeAllSelect();
  });

};

export { customSelect };
